"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { SupportedLocale } from "@/lib/i18n";
import { useBaseUrl } from "@/app/base-url-provider";

export default function WhistleblowingClient({
  locale,
}: {
  locale: SupportedLocale;
}) {
  const { baseUrl } = useBaseUrl();
  const [tenantCode, setTenantCode] = useState("");
  const [reportCode, setReportCode] = useState("");
  const router = useRouter();

  const handleTenantSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    router.push(`${baseUrl}/${tenantCode}`);
  };

  const handleReportSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    router.push(`${baseUrl}/report/${reportCode}`);
  };

  return (
    <div className="flex flex-col items-center pt-16 px-4">
      <div className="max-w-[500px] w-full space-y-8">
        <h1 className="text-3xl font-bold text-center">
          Portale Whistleblowing
        </h1>

        <div className="bg-white p-8 rounded-lg shadow-md space-y-8">
          <div className="space-y-6">
            <h2 className="text-lg font-semibold">Nuova Segnalazione</h2>
            <form onSubmit={handleTenantSubmit} className="space-y-4">
              <div className="space-y-2">
                <label
                  htmlFor="tenantCode"
                  className="block text-sm font-medium"
                >
                  Codice di accesso organizzazione
                </label>
                <Input
                  id="tenantCode"
                  type="text"
                  value={tenantCode}
                  onChange={(e) => setTenantCode(e.target.value)}
                  placeholder="Inserisci il codice fornito dalla tua organizzazione"
                  className="w-full"
                />
              </div>
              <Button type="submit" className="w-full">
                Invia Nuova Segnalazione
              </Button>
            </form>
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">oppure</span>
            </div>
          </div>

          <div className="space-y-6">
            <h2 className="text-lg font-semibold">Recupera Segnalazione</h2>
            <form onSubmit={handleReportSubmit} className="space-y-4">
              <div className="space-y-2">
                <label
                  htmlFor="reportCode"
                  className="block text-sm font-medium"
                >
                  Codice segnalazione
                </label>
                <Input
                  id="reportCode"
                  type="text"
                  value={reportCode}
                  onChange={(e) => setReportCode(e.target.value)}
                  placeholder="Inserisci il codice di 16 caratteri ricevuto"
                  className="w-full"
                />
              </div>
              <Button type="submit" className="w-full">
                Recupera Segnalazione
              </Button>
            </form>
          </div>
        </div>

        <p className="text-sm text-center text-gray-500">
          Questo sistema garantisce il completo anonimato delle segnalazioni
          attraverso l&apos;utilizzo di codici temporanei e crittografici.
        </p>
      </div>
    </div>
  );
}
